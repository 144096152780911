.ctn-messaging {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
}
.ctn-messages {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
}
.notification-box {
  border-radius: 0px 10px 10px 10px;
  border: 0.5px solid #dbdbdb;
  background: #f8fff9;
  padding: 16px;
}
.txt-time {
  color: #575757;
}
@media only screen and (min-width: 992px) {
  .ctn-messaging {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    min-height: 80vh;
  }
  .ctn-messages {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
  }

  .chat-screen {
    height: 100%;
    background-color: #ffffff;
    padding: 16px;
    border: 1px solid #d8d8d8;
    border-top: none;
  }
  .chat-screen-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  .chat-screen-empty {
    background-color: #ffffff;
    height: 100vh;
  }
  .chat-screen-top {
    height: calc(100vh - 260px);
    overflow-y: scroll;
    padding: 0 8px;
  }
  .chat-screen-bottom {
    margin-top: 16px;
  }
  .msg-box {
    padding: 16px 24px;
    margin-bottom: 16px;
    width: 100%;
  }

  .msg-box-sent {
    background-color: #f7f4ff;
    border-radius: 20px 20px 0 20px;
    align-self: flex-end;
    /* margin-left: 16px; */
  }

  .msg-box-received {
    border: 1px solid #dbdbdb;
    background-color: #ffffff;
    border-radius: 0 20px 20px 20px;
    align-self: flex-start;
  }
  .history {
  }

  .info {
    padding: 24px 32px;
  }

  .chat-snippet {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .history-component-spacing {
    padding-left: 20%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 8px;
  }
  .history-component-spacing-active {
    padding-left: 20%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 8px;
    background-color: #ffffff;
  }

  .history-title-spacing {
    padding: 16px 0 16px 20%;
  }

  .f-gray {
    color: #a6a7a8;
  }
  .notification-box {
    margin-bottom: 16px;
  }

  .chatBox {
    /* flex: 5.5; */
    background-color: #ffffff;
  }

  .chatBoxWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .chatBoxTop {
    height: 800px;

    overflow-y: scroll;
    padding-right: 10px;
  }

  .chatBoxBottom {
    margin-top: 5px;
    align-self: flex-end;
  }
}
@media only screen and (min-width: 1200px) {
}
