.bg-women {
  background-image: url('../images/yesllow-top-model.jpg');
  height: 368px;
  /* width: 368px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 3px;
}
.bg-men {
  background-image: url('../images/blue-hoodie-model.jpg');
  height: 368px;
  /* width: 368px; */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 3px;
}
.bg-kids {
  background-image: url('../images/bunny-ears-model.jpg');
  height: 368px;
  /* width: 368px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 3px;
}

.link-cat {
  text-align: center;
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: '#481CFB';
}

.bg-white {
  background-color: #ffffff;
}

.section-spacing {
  margin-bottom: 32px;
}
