.c5-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  grid-gap: 16px;
}
.feed-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  /* margin: 0 -4px; */
}

.feed-grid__item {
  box-sizing: border-box;
  width: 50%;
  /* position: relative; */
}

.filterNav {
  /* display: flex; */
  width: 100%;
  margin-right: 24px;
}
.filter-nav {
  display: flex;
  flex-wrap: wrap;
}

.filter-nav__category {
  display: flex;
}

.filter-nav__other {
  display: flex;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .c5-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 16px;
  }
  .feed-grid {
    margin: 0 -8px;
  }

  .feed-grid__item {
    width: 33.3333333333%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .c5-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 16px;
  }
  .filter-nav {
    flex-direction: column;
  }

  .filter-nav__category {
    flex-direction: column;
  }

  .filter-nav__other {
    flex-direction: column;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .c5-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-gap: 16px;
  }
  .filter-nav {
    flex-direction: column;
  }

  .filter-nav__category {
    flex-direction: column;
  }

  .filter-nav__other {
    flex-direction: column;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .c5-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-gap: 16px;
  }
  .filter-nav {
    flex-direction: column;
  }

  .filter-nav__category {
    flex-direction: column;
  }

  .filter-nav__other {
    flex-direction: column;
  }
}

/* .c5-grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
} */
