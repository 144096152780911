:root {
  --click-blue: #4900ff;
  --font-family-montserrat: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  font-family: 'Lato', sans-serif;
  line-height: 1.5;
  background: #f5f6f8;
  overflow-x: hidden;
  font-size: 100%;
  color: #111111;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

p {
  font-size: 16px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a:link {
  /* color: ; */
  text-decoration: none;
}

a:visited {
  color: inherit;
}

a:hover {
  color: inherit;
}

input[type='text'] {
  font-family: var(--font-family-montserrat);
  color: '#111111';
  /* text-transform: capitalize; */
}
/* input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */
