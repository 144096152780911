.container {
  width: 90%;
  margin: 0 auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    width: 70%;
    margin: 0 auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container {
    width: 70%;
    margin: 0 auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    width: 70%;
    margin: 0 auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    width: 50%;
    margin: 0 auto;
  }
}

/* .c5-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  } */
